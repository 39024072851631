import Analytics from "./analytics/index";

((d: any, w: any) => {
    w.Boketto = {
        Analytics,
    };

    const keyName = "bkt_counter";

    if (w[keyName] && w[keyName].length > 0) {
        let index = -1;
        const length = w[keyName].length;

        while (++index < length) {
            w[keyName][index]();
        }
    }
})(document, window);
