// @ts-ignore
import Fingerprint2 from "fingerprintjs2";
// @ts-ignore
import isArray from "lodash-ts/isArray";
import { IFingerprintComponents } from "./interfaces/fingerprint";
import { waitUntilBrowserReady } from "./utils";

const FingerprintOptions = {
    excludes: { webgl: true, fonts: true, canvas: true, audio: true },
    fonts: { extendedJsFonts: false },
};

class Visitor {
    public static parse(): Promise<Visitor> {
        const visitor = new Visitor();
        return visitor
            .parseFingerprint()
            .then((components: IFingerprintComponents) => {
                visitor.analyzeFingerprint(components);
                return visitor;
            })
            .then(visitor.parseYandexMetrika);
    }

    private components: IFingerprintComponents;
    private yandexClientId: string;

    public parseFingerprint = (): Promise<any> => {
        return new Promise((resolve: any, reject: any) => {
            waitUntilBrowserReady(() => {
                Fingerprint2.getPromise(FingerprintOptions)
                    .then((components: IFingerprintComponents) =>
                        resolve(components),
                    )
                    .catch(reject);
            });
        });
    }

    public analyzeFingerprint(components: IFingerprintComponents) {
        this.components = components;
    }

    public parseYandexMetrika = (): Promise<Visitor> => {
        return new Promise((resolve: any, reject: any) => {
            waitUntilBrowserReady(() => {
                try {
                    this.analyzeYaMetrika();
                    resolve(this);
                } catch (e) {
                    reject(e);
                }
            });
        });
    }

    public analyzeYaMetrika() {
        const w = window as any;
        let metrika;

        for (const key in w) {
            if (w.hasOwnProperty(key) && key.indexOf("yaCounter") !== -1) {
                metrika = w[key];
                break;
            }
        }

        if (metrika != null && metrika.getClientID) {
            this.yandexClientId = metrika.getClientID();
        }
    }

    public toJson() {
        return {
            yandexClientId: this.yandexClientId || null,
            ...this.getFlattenComponents(),
        };
    }

    private getFlattenComponents() {
        return this.components.reduce((obj: any, current: any) => {
            obj[current.key] = isArray(current.value)
                ? current.value.join(";")
                : current.value;
            return obj;
        }, {});
    }
}

export default Visitor;
