// @ts-ignore
import Fingerprint2 from "fingerprintjs2";
import "whatwg-fetch";
import { IFingerprintComponents } from "./interfaces/fingerprint";
import Page from "./Page";
import Visitor from "./Visitor";

interface IAnalyticsOptions {
    id: string;
}

class Analytics {
    constructor(
        private options: IAnalyticsOptions,
        private API_URL: string = process.env.API_URL
    ) {
        this.parseVisitor()
            .then(this.parsePage)
            .then(this.sendHit);
    }

    private parseVisitor(): Promise<any> {
        return Visitor.parse();
    }

    private parsePage = (visitor: Visitor) => {
        const page = Page.parse();

        return { visitor, page };
    };

    private sendHit = ({ visitor, page }: { visitor: Visitor; page: Page }) => {
        const data = {
            counterID: this.options.id,
            ...visitor.toJson(),
            ...page.toJson(),
        };

        // console.log("sendHit to", `${this.API_URL}/api/hits`, "data", data);

        fetch(`${this.API_URL}/api/hits`, {
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then(() => console.log("Yay!"))
            .catch(() => console.log("Oh, no!"));
    };
}

export default Analytics;
