interface IPageParams {
    pageTitle: string;
    referrer: string;
    currentUrl: string;
}

class Page {
    public static parse() {
        return new Page({
            currentUrl: location.href,
            pageTitle: document.title,
            referrer: document.referrer,
        });
    }

    public pageTitle: string;
    public referrer: string;
    public currentUrl: string;

    constructor({ pageTitle, referrer, currentUrl }: IPageParams) {
        this.pageTitle = pageTitle || "";
        this.referrer = referrer || "";
        this.currentUrl = currentUrl || "";
    }

    public toJson() {
        return {
            currentUrl: this.currentUrl,
            pageTitle: this.pageTitle,
            referrer: this.referrer,
        };
    }
}

export default Page;
